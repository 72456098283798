import React, {
  memo,
  FC,
  useCallback,
} from 'react';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { useTransferMutation } from 'generated/schemas/mutations';
import { Button } from 'uikit/Buttons/Button';
import { themeSelector } from 'lib/features/theme';
import { Theme } from 'uikit/common/types';
import { refillMatic } from 'lib/features/sdk/thunks';
import { refillMaticLoadingSelector } from 'lib/features/sdk';
import { selectedAddressSelector } from 'lib/features/wallet';
import { useAuth } from 'common/contexts/Auth/AuthContext';
import { GetMaticProps } from './types';

export const GetMatic: FC<GetMaticProps> = memo(({ className }) => {
  const theme = useAppSelector(themeSelector);
  const loading = useAppSelector(refillMaticLoadingSelector);
  const selectedAddress = useAppSelector(selectedAddressSelector);
  const dispatch = useAppDispatch();
  const [transfer] = useTransferMutation();

  const onRefillMatic = useCallback(async () => {
    return dispatch(refillMatic({ transfer }));
  }, [transfer, dispatch]);

  const { runEvent } = useAuth(onRefillMatic);

  if (!selectedAddress) return null;

  return (
    <Button
      variant={theme === Theme.dark ? 'black' : 'white'}
      theme={theme}
      loading={loading}
      className={className}
      onClick={runEvent}
      data-testid="button-getmatic"
    >
      Get Matic
    </Button>
  );
});
