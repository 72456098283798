import { memo, FC } from 'react';
import cn from 'classnames';
import { useAppSelector } from 'lib/hooks';
import { Box } from 'uikit/Box';
import { Spinner } from 'uikit/Spinner';
import { themeSelector } from 'lib/features/theme';
import { fixedBalanceSelector } from 'lib/features/wallet/selectors';
import { loadingBalanceSelector } from 'lib/features/wallet';
import classes from './AccountBalance.module.scss';
import { AccountBalanceProps } from './types';

export const AccountBalance: FC<AccountBalanceProps> = memo(({ className }) => {
  const loadingBalance = useAppSelector(loadingBalanceSelector);
  const theme = useAppSelector(themeSelector);
  const { tee, matic } = useAppSelector(fixedBalanceSelector);

  return (
    <Box className={cn(classes.balance, className, classes[theme])}>
      {(!matic || !tee) && loadingBalance && (
        <Spinner fullscreen classNameWrap={classes.spinnerWrap} />
      )}
      <span className={classes.text} data-testid="account-balance">{matic} MATIC / {tee} TEE</span>
    </Box>
  );
});